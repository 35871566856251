import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { HeaderMenu } from "../../components/HeaderMenu.comp";
import { useNavigate } from "react-router-dom";
import GaugeOne from "../../components/charts/GaugeOne.chart";
import LoginContext from "../../context/login_context/LoginContext";
import { ListMedidores } from "../../API/ListMedidores.api";
import { LineFour } from "../../components/charts/LineFour.chart";

import * as htmlToImage from "html-to-image";
import GaugeOneElectric from "../../components/charts/GaugeOneElectric.chart";
import { LineFive } from "../../components/charts/LineFive.chart";
import { LineOneDay } from "../../components/charts/MonitorFijo/LineOneDay.chart";
import { CheckForUpdates } from "../../API/CheckForUpdates.api";
import { VERSION } from "../../router/Url";

import LaunchIcon from '@mui/icons-material/Launch';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { ChartsElectricFijo } from "./ChartsElectricFijo.page";
import { GetDataTAbleFaena } from "../../API/MonitorFijo/GetDataTAbleFaena.api";
import { ObtenerSumaPS_PN_DA } from "../../API/MonitorFijo/ObtenerSumaPS_PN_DA.api";
import { FormatNumberCL } from "../../mutations/FormatNumeral.Mutation";
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

Date.prototype.getWeekNumber = function () {
  var d = new Date(+this);  //Creamos un nuevo Date con la fecha de "this".
  d.setHours(0, 0, 0, 0);   //Nos aseguramos de limpiar la hora.
  d.setDate(d.getDate() + 4 - (d.getDay() || 7)); // Recorremos los días para asegurarnos de estar "dentro de la semana"
  //Finalmente, calculamos redondeando y ajustando por la naturaleza de los números en JS:
  return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
};

let ssggQuery = [
  {
    CONTRATO: "E-SMART",
    EMPRESA: "AGROINDUSTRIAL EL PAICO S.A.",
    FUENTE: "PANTALON NORTE",
    SENSOR: "m1",
  },
  {
    CONTRATO: "E-SMART",
    EMPRESA: "AGROINDUSTRIAL EL PAICO S.A.",
    MEDIDOR: "PANTALON SUR",
    SENSOR: "m1",
  },
  {
    CONTRATO: "NESPRA",
    EMPRESA: "AGROINDUSTRIAL EL PAICO S.A.",
    MEDIDOR: "ANILLO PRESION",
    SENSOR: "m1",
  },
  {
    CONTRATO: "NESPRA",
    EMPRESA: "AGROINDUSTRIAL EL PAICO S.A.",
    MEDIDOR: "APV",
    SENSOR: "m1",
  },
  {
    CONTRATO: "NESPRA",
    EMPRESA: "AGROINDUSTRIAL EL PAICO S.A.",
    MEDIDOR: "BANDEJAS",
    SENSOR: "m1",
  },
  {
    CONTRATO: "NESPRA",
    EMPRESA: "AGROINDUSTRIAL EL PAICO S.A.",
    MEDIDOR: "BANCOS DE AGUA",
    SENSOR: "m1",
  },
];
let SSGG_A = [
  "PANTALON NORTE","PANTALON SUR","ANILLO PRESION","APV","BANDEJAS","BANCOS DE AGUA"
]

let FAENA_ = [
    {
      "CONTRATO":"E-SMART",
      "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
      "MEDIDOR": "PANTALON NORTE"
    },
    {
      "CONTRATO": "NESPRA",
      "EMPRESA": "AGROINDUSTRIAL EL PAICO S.A.",
      "MEDIDOR": "BANCOS DE AGUA"
    },
    {
      "CONTRATO":"NESPRA",
      "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
      "MEDIDOR": "MENUDENCIAS",
    },
    {
      "CONTRATO":"NESPRA",
      "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
      "MEDIDOR":"EVISCERADO PAVO"
    },
    {
      "CONTRATO": "NESPRA",
      "EMPRESA": "AGROINDUSTRIAL EL PAICO S.A.",
      "MEDIDOR": "MATADERO PAVO"
    }
  ]
let FAENA_A = [
  "PANTALON NORTE","BANCOS DE AGUA","EVISCERADO PAVO","MATADERO PAVO"
]
let FAENA_B = [
  "PANTALON NORTE","BANCOS DE AGUA","MATADERO PAVO"
  // "BANCOS DE AGUA"
]

export const SsGgMonitorFijo = () => {
  const [ListMedidores_, setListMedidores_] = useState([]);
  const [ListMedidores_oneday, setListMedidores_oneday] = useState([]);
  const [ShowmetheModal_, setShowmetheModal_] = useState(false);
  const [ShowmetheModal_ACUM, setShowmetheModal_ACUM] = useState(false);
  const [MedidorToModal, setMedidorToModal] = useState("");
  const [LastTime, setLastTime] = useState("");
  const [OpacityPages, setOpacityPages] = useState(0);//default 0
  const [IsOpacity, setIsOpacity] = useState(false);
  const [DesdeHAstaFecha, setDesdeHAstaFecha] = useState('');
  //valor consumo dia anterior
  const [AntValueData, setantValueData] = useState(null);
  /** ********************************************************* tabla editada ********************************************************* */
  const [IndicadoresFaenaTabla, setIndicadoresFaenaTabla] = useState({
    OBJ_CPF: 0, RSA_CPF: 0, LUN_CPF: 0, MAR_CPF: 0, MIE_CPF: 0, JUE_CPF: 0, VIE_CPF: 0, SAB_CPF: 0, DOM_CPF: 0,
    OBJ_RHC: 0, RSA_RHC: 0, LUN_RHC: 0, MAR_RHC: 0, MIE_RHC: 0, JUE_RHC: 0, VIE_RHC: 0, SAB_RHC: 0, DOM_RHC: 0,
    OBJ_RC: 0, RSA_RC: 0, LUN_RC: 0, MAR_RC: 0, MIE_RC: 0, JUE_RC: 0, VIE_RC: 0, SAB_RC: 0, DOM_RC: 0,
    OBJ_RPM: 0, RSA_RPM: 0, LUN_RPM: 0, MAR_RPM: 0, MIE_RPM: 0, JUE_RPM: 0, VIE_RPM: 0, SAB_RPM: 0, DOM_RPM: 0,
    OBJ_ABS: 0, RSA_ABS: 0, LUN_ABS: 0, MAR_ABS: 0, MIE_ABS: 0, JUE_ABS: 0, VIE_ABS: 0, SAB_ABS: 0, DOM_ABS: 0,
    OBJ_DG: 0, RSA_DG: 0, LUN_DG: 0, MAR_DG: 0, MIE_DG: 0, JUE_DG: 0, VIE_DG: 0, SAB_DG: 0, DOM_DG: 0,
    OBJ_DP: 0, RSA_DP: 0, LUN_DP: 0, MAR_DP: 0, MIE_DP: 0, JUE_DP: 0, VIE_DP: 0, SAB_DP: 0, DOM_DP: 0,
    OBJ_EXP: 0, RSA_EXP: 0, LUN_EXP: 0, MAR_EXP: 0, MIE_EXP: 0, JUE_EXP: 0, VIE_EXP: 0, SAB_EXP: 0, DOM_EXP: 0,
    OBJ_GPV: 0, RSA_GPV: 0, LUN_GPV: 0, MAR_GPV: 0, MIE_GPV: 0, JUE_GPV: 0, VIE_GPV: 0, SAB_GPV: 0, DOM_GPV: 0,
    OBJ_AC: 0, RSA_AC: 0, LUN_AC: 0, MAR_AC: 0, MIE_AC: 0, JUE_AC: 0, VIE_AC: 0, SAB_AC: 0, DOM_AC: 0,
    OBJ_CA: 0, RSA_CA: 0, LUN_CA: 0, MAR_CA: 0, MIE_CA: 0, JUE_CA: 0, VIE_CA: 0, SAB_CA: 0, DOM_CA: 0,
  })
  const [IndicadoresFaenaCAlculo, setIndicadoresFaenaCAlculo] = useState({
    RSEM_CPF: 0,
    RSEM_RHC: 0,
    RSEM_RC: 0,
    RSEM_RPM: 0,
    RSEM_ABS: 0,
    RSEM_DG: 0,
    RSEM_DP: 0,
    RSEM_EXP: 0,
    RSEM_GPV: 0,
    RSEM_AC: 0,
    RSEM_CA: 0
  })

  const IndicadoresFaenaTablaClear = {
    OBJ_CPF: 0, RSA_CPF: 0, LUN_CPF: 0, MAR_CPF: 0, MIE_CPF: 0, JUE_CPF: 0, VIE_CPF: 0, SAB_CPF: 0, DOM_CPF: 0,
    OBJ_RHC: 0, RSA_RHC: 0, LUN_RHC: 0, MAR_RHC: 0, MIE_RHC: 0, JUE_RHC: 0, VIE_RHC: 0, SAB_RHC: 0, DOM_RHC: 0,
    OBJ_RC: 0, RSA_RC: 0, LUN_RC: 0, MAR_RC: 0, MIE_RC: 0, JUE_RC: 0, VIE_RC: 0, SAB_RC: 0, DOM_RC: 0,
    OBJ_RPM: 0, RSA_RPM: 0, LUN_RPM: 0, MAR_RPM: 0, MIE_RPM: 0, JUE_RPM: 0, VIE_RPM: 0, SAB_RPM: 0, DOM_RPM: 0,
    OBJ_ABS: 0, RSA_ABS: 0, LUN_ABS: 0, MAR_ABS: 0, MIE_ABS: 0, JUE_ABS: 0, VIE_ABS: 0, SAB_ABS: 0, DOM_ABS: 0,
    OBJ_DG: 0, RSA_DG: 0, LUN_DG: 0, MAR_DG: 0, MIE_DG: 0, JUE_DG: 0, VIE_DG: 0, SAB_DG: 0, DOM_DG: 0,
    OBJ_DP: 0, RSA_DP: 0, LUN_DP: 0, MAR_DP: 0, MIE_DP: 0, JUE_DP: 0, VIE_DP: 0, SAB_DP: 0, DOM_DP: 0,
    OBJ_EXP: 0, RSA_EXP: 0, LUN_EXP: 0, MAR_EXP: 0, MIE_EXP: 0, JUE_EXP: 0, VIE_EXP: 0, SAB_EXP: 0, DOM_EXP: 0,
    OBJ_GPV: 0, RSA_GPV: 0, LUN_GPV: 0, MAR_GPV: 0, MIE_GPV: 0, JUE_GPV: 0, VIE_GPV: 0, SAB_GPV: 0, DOM_GPV: 0,
    OBJ_AC: 0, RSA_AC: 0, LUN_AC: 0, MAR_AC: 0, MIE_AC: 0, JUE_AC: 0, VIE_AC: 0, SAB_AC: 0, DOM_AC: 0,
    OBJ_CA: 0, RSA_CA: 0, LUN_CA: 0, MAR_CA: 0, MIE_CA: 0, JUE_CA: 0, VIE_CA: 0, SAB_CA: 0, DOM_CA: 0,
  }
  
  const ClearTablaOPC = async () => {
    let seguro = confirm('Esta seguro de borrar todo el PANEL, esta accion no es reversible.')
    if(seguro) setIndicadoresFaenaTabla(IndicadoresFaenaTablaClear)
  }

  /** ********************************************************* tabla editada ********************************************************* */
  
  let IsOacitiValue = 0

  const [_width, set_width] = useState(window.innerWidth);
  const [_height, set_height] = useState(window.innerHeight);

  const navigate = useNavigate();

  const { LoginState, SetLogin, CloseLogin, GetLoginSession } = useContext(LoginContext);
//   console.log(_width)

  const getIMG = async (id) => {
    var node = document.getElementById(id);

    htmlToImage
      .toSvg(node)
      .then(function (dataUrl) {
        // console.log(dataUrl);
        let anchor = document.createElement("a");
        anchor.href = dataUrl;
        anchor.download = "MyQRIsHere.png";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        // bringMeTheCode()
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

/** genera opacidad positiva a la pagina */
  const upOpacity = async () => {
    let OacitiValue = 0
    let intervalo = setInterval(async() => {
      if(OacitiValue <= 0 && IsOacitiValue === 0){
        IsOacitiValue+=1
      }
      if(IsOacitiValue===1)OacitiValue += 0.05
      await setOpacityPages(OacitiValue)
      if(OacitiValue >= 1 && IsOacitiValue === 1){
        clearInterval(intervalo)
        setIsOpacity(true)
      }
      
    }, 100);//intervalo de velocidad de opacidad
  }
  /** genera opacidad negativa a la pagina */
  const downOpacity = async () => {
    let OacitiValue = 1
    let intervalo = setInterval(async() => {
      if(OacitiValue >= 1 && IsOacitiValue === 1){
        IsOacitiValue-=1
        setIsOpacity(false)
      }
      if(IsOacitiValue === 0)OacitiValue -= 0.05
      await setOpacityPages(OacitiValue)
      if(OacitiValue <= 0 && IsOacitiValue === 0){
        clearInterval(intervalo)
        setIsOpacity(false)
      }
    }, 100);//intervalo de velocidad de opacidad
  }

  const launchFullScreen = async (id) => {
    // console.log("fullscreen",id);
    let container = document.getElementById(id);
    // console.log("full screen")
    if (container.requestFullScreen) {
        container.requestFullScreen();
    } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen();
    } else if (container.webkitRequestFullScreen) {
        container.webkitRequestFullScreen();
    }
  };

  const GetListMedidores = async () => {
    let list_ = await ListMedidores({ empresa: LoginState.planta });
    let listFil = await list_["m1"].filter((fl) => fl.ELEMENTO === "HIDRICO" && (FAENA_A.includes(fl.MEDIDOR)));
    let fill_col_OneDay = await list_["m1"].filter((fl) => fl.ELEMENTO === "HIDRICO" && (FAENA_B.includes(fl.MEDIDOR)));
    // console.log(listFil)
    setListMedidores_(listFil);
    setListMedidores_oneday(fill_col_OneDay)
  };

  const ShowModalData = async (data) => {
    // console.log(data);
    setMedidorToModal(data);
    setShowmetheModal_(true);
  };

  const ShowModalData_ACUM = async (data) => {
    // console.log(data);
    setMedidorToModal(data);
    setShowmetheModal_ACUM(true);
  };

  const ActualizarPagina__watch = async () => {
    let chUpd = await CheckForUpdates({APP:"SSGGMONITOR"})
    ///hacer logica para que compare el incremental y actualizar en caso que corresponda
    // guardar el numeto en localstogare para compararlo
    let oldVersion = localStorage.getItem('CHECKUPDATEVERSION')
    let oln_Version = JSON.parse(oldVersion)
    // console.log(oln_Version.CHECKUPDATE,chUpd.VERSION_NEW);
    // console.log(oln_Version.CHECKUPDATE,oldVersion);
    // console.log("checkUpdates=>>>>>>",chUpd, oln_Version);
    // if(oln_Version.FECHA < chUpd.FECHA_NEW){
      if(oln_Version.CHECKUPDATE < chUpd.VERSION_NEW){
        // console.log("SE DEBE ACTUALIZAR LA PAGINA");
        window.location.reload()
      }
    // }
    // console.log("oldVersion", oln_Version.FECHA);
  }
  const FirstUpdateSaveVersion = async () => {
    // console.log("ACTUALIZADOR=>>>>>>");
    let chUpd = await CheckForUpdates({APP:"SSGGMONITOR"})
    let versionOld = {FECHA: chUpd.FECHA_NEW, CHECKUPDATE: chUpd.VERSION_NEW}
    localStorage.setItem('CHECKUPDATEVERSION',JSON.stringify(versionOld))
  }

  const HandleCloseSession = async () => {
    CloseLogin();
    navigate("/");
  };

  const ObtenerTAblaActualizada = async () => {
    // console.log("carga la tabla", new Date().toLocaleString())
    let tablaSrv = await GetDataTAbleFaena({PLANTA:LoginState.planta, AREA: "FAENA"})
    if(tablaSrv){
        // console.log(tablaSrv);
        let items_ = Object.keys(IndicadoresFaenaTabla)
        let items_2 = Object.keys(IndicadoresFaenaCAlculo)
        // console.log(items_,items_2);
        let Bulk_tabla = {}
        let Bulk_calculo = {}
        for (let i = 0; i < items_.length; i++) {
            const i_i = items_[i];
            Bulk_tabla[i_i] = tablaSrv[0][i_i]
            // console.log({[i_i]:tablaSrv[0][i_i]});
        }
        for (let i = 0; i < items_2.length; i++) {
            const i_i = items_2[i];
            Bulk_calculo[i_i] = tablaSrv[0][i_i]
            // console.log({[i_i]:tablaSrv[0][i_i]});
        }
        // console.log(Bulk_tabla);
        // console.log(Bulk_calculo);
        setIndicadoresFaenaTabla(Bulk_tabla);
        setIndicadoresFaenaCAlculo(Bulk_calculo);
        // setTableSRVData(Bulk_tabla);
    }
    
  }

  const Obtener_consumoDia_Anterior = async () => {
    let data = await ObtenerSumaPS_PN_DA()
    if(data?.body){
      let bdy = data.body
      // console.log(bdy)
      setantValueData(bdy)
    }else{
      setantValueData(null)
    }
  }

  useEffect(() => {
    // console.log("SE EJECUTO EL ACTUALIZADOR");
    const MakeDateToFrom = async () => {
      let nSem = new Date().getWeekNumber()
      let mm = new Date().getMonth();
      let YYYY = new Date().getFullYear();
      let i = new Date(String(YYYY),0,(nSem-1)*7+1).getDate();
      let f = new Date(String(YYYY),0,(nSem-1)*7+7).getDate();
      // console.log(`${i<10?"0"+i:i}/${mm<10?"0"+mm:mm}/${YYYY} al ${f<10?"0"+f:f}/${mm<10?"0"+mm:mm}/${YYYY}`);
      setDesdeHAstaFecha(`${i<10?"0"+i:i}/${mm<10?"0"+mm:mm}/${YYYY} al ${f<10?"0"+f:f}/${mm<10?"0"+mm:mm}/${YYYY}`);
    }
    FirstUpdateSaveVersion()
    ObtenerTAblaActualizada()
    MakeDateToFrom()
    setInterval(() => {
      ActualizarPagina__watch()
      ObtenerTAblaActualizada()
      MakeDateToFrom()
    }, 1000*30);
    return () => {
    }
  }, [])
  
  useEffect(() => {
    let fecha = new Date().toLocaleString()
    setLastTime(fecha)
    GetListMedidores()
    setInterval(() => {
      GetListMedidores()
      let fecha_ = new Date().toLocaleString()
      setLastTime(fecha_)
    }, 1000*60*15);
    return () => {
      GetListMedidores();
    };
  }, []);

  useEffect(() => {
    Obtener_consumoDia_Anterior()
    setInterval(() => {
      Obtener_consumoDia_Anterior()
    }, 1000*60*60);
    return () => {
      Obtener_consumoDia_Anterior()
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // console.log("IsOpacity",new Date().toISOString(),IsOpacity);
      if(IsOpacity){
        downOpacity()
      }
      else{
        upOpacity()
      }
    }, 1000*30);//TIEMPO DE VISTA PARA CADA PAGINA
  }, [IsOpacity])

  // useEffect(() => {
  //   const Calculalo = async () => {
  //       let objTitle = Object.keys(IndicadoresFaenaTabla)
  //     // console.log(objTitle);
  //     let ind = IndicadoresFaenaTabla
  //     let semPor = [
  //       ["LUN_CPF","MAR_CPF","MIE_CPF","JUE_CPF","VIE_CPF","SAB_CPF","DOM_CPF"],
  //       ["LUN_RHC","MAR_RHC","MIE_RHC","JUE_RHC","VIE_RHC","SAB_RHC","DOM_RHC"],
  //       ["LUN_RC","MAR_RC","MIE_RC","JUE_RC","VIE_RC","SAB_RC","DOM_RC"],
  //       ["LUN_RPM","MAR_RPM","MIE_RPM","JUE_RPM","VIE_RPM","SAB_RPM","DOM_RPM"],
  //       ["LUN_ABS","MAR_ABS","MIE_ABS","JUE_ABS","VIE_ABS","SAB_ABS","DOM_ABS"],
  //       ["LUN_DG","MAR_DG","MIE_DG","JUE_DG","VIE_DG","SAB_DG","DOM_DG"],
  //       ["LUN_DP","MAR_DP","MIE_DP","JUE_DP","VIE_DP","SAB_DP","DOM_DP"],
  //       ["LUN_EXP","MAR_EXP","MIE_EXP","JUE_EXP","VIE_EXP","SAB_EXP","DOM_EXP"],
  //       ["LUN_GPV","MAR_GPV","MIE_GPV","JUE_GPV","VIE_GPV","SAB_GPV","DOM_GPV"],
  //       ["LUN_AC","MAR_AC","MIE_AC","JUE_AC","VIE_AC","SAB_AC","DOM_AC"],
  //       ["LUN_CA","MAR_CA","MIE_CA","JUE_CA","VIE_CA","SAB_CA","DOM_CA"],
  //     ]
  //     let rcm = [
  //       "RSEM_CPF",
  //       "RSEM_RHC",
  //       "RSEM_RC",
  //       "RSEM_RPM",
  //       "RSEM_ABS",
  //       "RSEM_DG",
  //       "RSEM_DP",
  //       "RSEM_EXP",
  //       "RSEM_GPV",
  //       "RSEM_AC",
  //       "RSEM_CA"
  //     ]//RSEM_CPF
  //     let rcmObj = { 
  //       "RSEM_CPF": 0,
  //       "RSEM_RHC": 0,
  //       "RSEM_RC": 0,
  //       "RSEM_RPM": 0,
  //       "RSEM_ABS": 0,
  //       "RSEM_DG": 0,
  //       "RSEM_DP": 0,
  //       "RSEM_EXP": 0,
  //       "RSEM_GPV": 0,
  //       "RSEM_AC": 0,
  //       "RSEM_CA": 0,
  //     }
  //     for (let idm = 0; idm < semPor.length; idm++) {
  //       const idm_ = semPor[idm];
  //       let calculeDivisor = 0
  //       let calculesuma = 0
  //       for (let dp = 0; dp < idm_.length; dp++) {
  //         const dp_ = idm_[dp];
  //         calculesuma += IndicadoresFaenaTabla[dp_]
  //         calculeDivisor += IndicadoresFaenaTabla[dp_]!==0?1:0
  //       }
  //       // console.log(calculeDivisor);
  //       let Prom_edio = await calculeDivisor===0?0:Number(Number(calculesuma/calculeDivisor).toFixed(2))
        
  //       rcmObj[rcm[idm]] = Prom_edio
  //     }
  //     setIndicadoresFaenaCAlculo(rcmObj);
  //   }
  //   Calculalo()
  // // }, [IndicadoresFaenaTabla.LUN_CPF,IndicadoresFaenaTabla.MAR_CPF,IndicadoresFaenaTabla.MIE_CPF,IndicadoresFaenaTabla.JUE_CPF,IndicadoresFaenaTabla.VIE_CPF,IndicadoresFaenaTabla.SAB_CPF,IndicadoresFaenaTabla.DOM_CPF])
  // }, [IndicadoresFaenaTabla])
   
  
  return (
    <>
      <div className="closessggmonit">
        <span onClick={()=>HandleCloseSession()} title="Salir"><LaunchIcon /></span>
      </div>
      <div style={{width:'1920px', height:'1080px'}} id="container--monitor" onClick={() => launchFullScreen("container--monitor")}>
      {/* PAGINA 1 INDICADORES ELECTRICOS */}
        <div style={{height: '1080px', width: '1920px', position:"fixed",top:'0px', backgroundColor: '#fff'}} >
          <div className="row">
            <div className="col-12 text-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-3">
                    <span className="float-left" style={{fontSize:'65px'}}>💧</span><br/>
          {/*<span>Ultima Actualización:</span><br/>
                    <span className=""><b>{LastTime}</b></span>*/}
                  </div>
                  <div className="col-6">
                    <h4 className="mt-3" style={{fontWeight:"bolder"}}><b>CONSUMO HIDRICO PLANTA</b></h4>
                  </div>
                  <div className="col-3">
                    <img src={require("../../assets/logo_ariztia.png")} style={{ height: "100px" }} className="px-5 float-right" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 py-2 card-chart-line">
              <div className="row">
                {ListMedidores_.map((cl) => (
                  <div
                    key={cl._id}
                    id={cl._id}
                    style={{ cursor: "pointer", width: 100/FAENA_A.length+'%'}}
                  >
                    <GaugeOne
                      key={cl._id + "01"}
                      DATA={cl}
                      ShowModalData={ShowModalData}
                      ShowModalData_ACUM={ShowModalData_ACUM}
                    />
                  </div>
                ))}
                {/* SUMA DE PANTALONES */}
                {
                  AntValueData? <div className="pr-5" style={{position: "absolute", top: "50%", right: 0, zIndex:99999999}}>
                    <span className="lead" style={{}}>
                      Consumo Pantalones {AntValueData.fecha} 
                    </span>
                    <span style={{fontSize: 20, marginLeft: 5}}>
                      <b className="strong">{FormatNumberCL(AntValueData.valor)} {AntValueData.unmed}</b>
                    </span>
                  </div>
                  :null
                }
               
                <div className="col-12">
                  <div className="row">
                  {
                    ListMedidores_oneday?.map(oneMap => (
                      <div className="col-4" key={oneMap._id}>
                        <LineOneDay DATA={oneMap}/>
                      </div>
                    ))
                  }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">version {VERSION}</div>
          </div>
        </div>
        {/* PAGINA 2 CONSUMO ELECTRTICO */}
        {/* <div id="pagina_2" style={{height: '1080px', width: '1920px',opacity:1, position:"fixed",top:'0px', backgroundColor: '#fff'}}>
          <div className="col-12 text-center">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <span className="float-left" style={{fontSize:'65px'}}>⚡</span><br/>
                  <span className="mt-4">Ultima Actualización:</span><br/>
                  <span className=""><b>{LastTime}</b></span>
                </div>
                <div className="col-6">
                  <h4 className="mt-3" style={{fontWeight:"bolder"}}><b>CONSUMO ELECTRICO PLANTA</b></h4>
                </div>
                <div className="col-3">
                  <img src={require("../../assets/logo_ariztia.png")} style={{ height: "100px" }} className="px-5 float-right" />
                </div>
              </div>
            </div>
            <ChartsElectricFijo />
          <div className="col-12 text-center">version {VERSION}</div>
          </div>
        </div> */}

        {/* PAGINA 3 INDICADORES DE FAENA OpacityPages */}
        <div id="pagina_2" style={{height: '1080px', width: '1920px',opacity:OpacityPages, position:"fixed",top:'0px', backgroundColor: '#fff',border: "1px solid black"}}>
          <div className="col-12 text-center">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3">
                        <span>Ultima Actualización:</span><br/>
                        <span className=""><b>{LastTime}</b></span>
                      </div>
                      <div className="col-6">
                        <h4 className="mt-3" style={{fontWeight:"bolder"}}><b>INDICADORES FAENA</b></h4>
                      </div>
                      <div className="col-3">
                        <img src={require("../../assets/logo_ariztia.png")} style={{ height: "100px" }} className="px-5 float-right" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-left"><span style={{fontSize: 20}}><b>{DesdeHAstaFecha}</b></span></div>
                <div className="col-12">
                  <table className="table table-bordered">
                    <thead className="" style={{backgroundColor: 'red', color: 'white'}}>
                      <tr>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Indicador</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Objetivo</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Resumen<br/>Semana<br/>Anterior</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Lunes</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Martes</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Miercoles</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Jueves</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Viernes</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Sabado</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Domingo</th>
                        <th style={{verticalAlign: 'middle', fontSize: 24}}>Resumen<br/>Semanal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* lineas de la tabla */}
                      <tr>
                        <th style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>% CUMPLIMIENTO<br/>FAENA</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><NorthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_CPF>0?IndicadoresFaenaTabla.OBJ_CPF+"%":null}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_CPF>0?IndicadoresFaenaTabla.RSA_CPF+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.LUN_CPF===0?null:IndicadoresFaenaTabla.OBJ_CPF>IndicadoresFaenaTabla.LUN_CPF?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.LUN_CPF>0?IndicadoresFaenaTabla.LUN_CPF+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MAR_CPF<=0?null:IndicadoresFaenaTabla.OBJ_CPF>IndicadoresFaenaTabla.MAR_CPF?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MAR_CPF>0?IndicadoresFaenaTabla.MAR_CPF+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MIE_CPF<=0?null:IndicadoresFaenaTabla.OBJ_CPF>IndicadoresFaenaTabla.MIE_CPF?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MIE_CPF>0?IndicadoresFaenaTabla.MIE_CPF+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.JUE_CPF<=0?null:IndicadoresFaenaTabla.OBJ_CPF>IndicadoresFaenaTabla.JUE_CPF?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.JUE_CPF>0?IndicadoresFaenaTabla.JUE_CPF+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.VIE_CPF<=0?null:IndicadoresFaenaTabla.OBJ_CPF>IndicadoresFaenaTabla.VIE_CPF?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.VIE_CPF>0?IndicadoresFaenaTabla.VIE_CPF+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.SAB_CPF<=0?null:IndicadoresFaenaTabla.OBJ_CPF>IndicadoresFaenaTabla.SAB_CPF?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.SAB_CPF>0?IndicadoresFaenaTabla.SAB_CPF+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.DOM_CPF<=0?null:IndicadoresFaenaTabla.OBJ_CPF>IndicadoresFaenaTabla.DOM_CPF?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.DOM_CPF>0?IndicadoresFaenaTabla.DOM_CPF+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaCAlculo.RSEM_CPF===0?null:IndicadoresFaenaTabla.OBJ_CPF>IndicadoresFaenaCAlculo.RSEM_CPF?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {String(IndicadoresFaenaCAlculo.RSEM_CPF)}%
                        </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>% RENDIMIENTO<br/>HIGADO/CORAZON</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><NorthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_RHC>0?IndicadoresFaenaTabla.OBJ_RHC+'%':null}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_RHC>0?IndicadoresFaenaTabla.RSA_RHC+'%':null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.LUN_RHC<=0?null:IndicadoresFaenaTabla.OBJ_RHC>IndicadoresFaenaTabla.LUN_RHC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.LUN_RHC>0?IndicadoresFaenaTabla.LUN_RHC+'%':null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MAR_RHC<=0?null:IndicadoresFaenaTabla.OBJ_RHC>IndicadoresFaenaTabla.MAR_RHC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MAR_RHC>0?IndicadoresFaenaTabla.MAR_RHC+'%':null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MIE_RHC<=0?null:IndicadoresFaenaTabla.OBJ_RHC>IndicadoresFaenaTabla.MIE_RHC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MIE_RHC>0?IndicadoresFaenaTabla.MIE_RHC+'%':null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.JUE_RHC<=0?null:IndicadoresFaenaTabla.OBJ_RHC>IndicadoresFaenaTabla.JUE_RHC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.JUE_RHC>0?IndicadoresFaenaTabla.JUE_RHC+'%':null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.VIE_RHC<=0?null:IndicadoresFaenaTabla.OBJ_RHC>IndicadoresFaenaTabla.VIE_RHC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.VIE_RHC>0?IndicadoresFaenaTabla.VIE_RHC+'%':null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.SAB_RHC<=0?null:IndicadoresFaenaTabla.OBJ_RHC>IndicadoresFaenaTabla.SAB_RHC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.SAB_RHC>0?IndicadoresFaenaTabla.SAB_RHC+'%':null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.DOM_RHC<=0?null:IndicadoresFaenaTabla.OBJ_RHC>IndicadoresFaenaTabla.DOM_RHC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.DOM_RHC>0?IndicadoresFaenaTabla.DOM_RHC+'%':null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaCAlculo.RSEM_RHC<=0?null:IndicadoresFaenaTabla.OBJ_RHC>IndicadoresFaenaCAlculo.RSEM_RHC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {String(IndicadoresFaenaCAlculo.RSEM_RHC)}%
                        </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>% RENDIMIENTO<br/>CONTRES</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><NorthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_RC>0?IndicadoresFaenaTabla.OBJ_RC+"%":null}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_RC>0?IndicadoresFaenaTabla.RSA_RC+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.LUN_RC<=0?null:IndicadoresFaenaTabla.OBJ_RC>IndicadoresFaenaTabla.LUN_RC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.LUN_RC>0?IndicadoresFaenaTabla.LUN_RC+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MAR_RC<=0?null:IndicadoresFaenaTabla.OBJ_RC>IndicadoresFaenaTabla.MAR_RC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MAR_RC>0?IndicadoresFaenaTabla.MAR_RC+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MIE_RC<=0?null:IndicadoresFaenaTabla.OBJ_RC>IndicadoresFaenaTabla.MIE_RC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MIE_RC>0?IndicadoresFaenaTabla.MIE_RC+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.JUE_RC<=0?null:IndicadoresFaenaTabla.OBJ_RC>IndicadoresFaenaTabla.JUE_RC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.JUE_RC>0?IndicadoresFaenaTabla.JUE_RC+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.VIE_RC<=0?null:IndicadoresFaenaTabla.OBJ_RC>IndicadoresFaenaTabla.VIE_RC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.VIE_RC>0?IndicadoresFaenaTabla.VIE_RC+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.SAB_RC<=0?null:IndicadoresFaenaTabla.OBJ_RC>IndicadoresFaenaTabla.SAB_RC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.SAB_RC>0?IndicadoresFaenaTabla.SAB_RC+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.DOM_RC<=0?null:IndicadoresFaenaTabla.OBJ_RC>IndicadoresFaenaTabla.DOM_RC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.DOM_RC>0?IndicadoresFaenaTabla.DOM_RC+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaCAlculo.RSEM_RC<=0?null:IndicadoresFaenaTabla.OBJ_RC>IndicadoresFaenaCAlculo.RSEM_RC?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaCAlculo.RSEM_RC}%
                        </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>RINDE POLLO S/M<br/>S/ MARINAR</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><NorthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_RPM>0?IndicadoresFaenaTabla.OBJ_RPM+"%":null}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_RPM>0?IndicadoresFaenaTabla.RSA_RPM+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.LUN_RPM<=0?null:IndicadoresFaenaTabla.OBJ_RPM>IndicadoresFaenaTabla.LUN_RPM?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.LUN_RPM>0?IndicadoresFaenaTabla.LUN_RPM+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MAR_RPM<=0?null:IndicadoresFaenaTabla.OBJ_RPM>IndicadoresFaenaTabla.MAR_RPM?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MAR_RPM>0?IndicadoresFaenaTabla.MAR_RPM+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MIE_RPM<=0?null:IndicadoresFaenaTabla.OBJ_RPM>IndicadoresFaenaTabla.MIE_RPM?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MIE_RPM>0?IndicadoresFaenaTabla.MIE_RPM+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.JUE_RPM<=0?null:IndicadoresFaenaTabla.OBJ_RPM>IndicadoresFaenaTabla.JUE_RPM?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.JUE_RPM>0?IndicadoresFaenaTabla.JUE_RPM+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.VIE_RPM<=0?null:IndicadoresFaenaTabla.OBJ_RPM>IndicadoresFaenaTabla.VIE_RPM?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.VIE_RPM>0?IndicadoresFaenaTabla.VIE_RPM+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.SAB_RPM<=0?null:IndicadoresFaenaTabla.OBJ_RPM>IndicadoresFaenaTabla.SAB_RPM?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.SAB_RPM>0?IndicadoresFaenaTabla.SAB_RPM+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.DOM_RPM<=0?null:IndicadoresFaenaTabla.OBJ_RPM>IndicadoresFaenaTabla.DOM_RPM?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.DOM_RPM>0?IndicadoresFaenaTabla.DOM_RPM+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaCAlculo.RSEM_RPM<=0?null:IndicadoresFaenaTabla.RSA_RPM>IndicadoresFaenaCAlculo.OBJ_RPM?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaCAlculo.RSEM_RPM}%
                        </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>% ABSORCION</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><NorthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_ABS>0?IndicadoresFaenaTabla.OBJ_ABS+"%":null}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_ABS>0?IndicadoresFaenaTabla.RSA_ABS+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.LUN_ABS<=0?null:IndicadoresFaenaTabla.OBJ_ABS>IndicadoresFaenaTabla.LUN_ABS?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.LUN_ABS>0?IndicadoresFaenaTabla.LUN_ABS+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MAR_ABS<=0?null:IndicadoresFaenaTabla.OBJ_ABS>IndicadoresFaenaTabla.MAR_ABS?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MAR_ABS>0?IndicadoresFaenaTabla.MAR_ABS+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MIE_ABS<=0?null:IndicadoresFaenaTabla.OBJ_ABS>IndicadoresFaenaTabla.MIE_ABS?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MIE_ABS>0?IndicadoresFaenaTabla.MIE_ABS+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.JUE_ABS<=0?null:IndicadoresFaenaTabla.OBJ_ABS>IndicadoresFaenaTabla.JUE_ABS?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.JUE_ABS>0?IndicadoresFaenaTabla.JUE_ABS+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.VIE_ABS<=0?null:IndicadoresFaenaTabla.OBJ_ABS>IndicadoresFaenaTabla.VIE_ABS?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.VIE_ABS>0?IndicadoresFaenaTabla.VIE_ABS+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.SAB_ABS<=0?null:IndicadoresFaenaTabla.OBJ_ABS>IndicadoresFaenaTabla.SAB_ABS?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.SAB_ABS>0?IndicadoresFaenaTabla.SAB_ABS+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.DOM_ABS<=0?null:IndicadoresFaenaTabla.OBJ_ABS>IndicadoresFaenaTabla.DOM_ABS?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.DOM_ABS>0?IndicadoresFaenaTabla.DOM_ABS+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaCAlculo.RSEM_ABS<=0?null:IndicadoresFaenaTabla.OBJ_ABS>IndicadoresFaenaCAlculo.RSEM_ABS?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaCAlculo.RSEM_ABS}%
                        </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>% DECOMISO<br/>GRANJA</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><SouthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_DG>0?IndicadoresFaenaTabla.OBJ_DG+"%":null}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_DG>0?IndicadoresFaenaTabla.RSA_DG+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.LUN_DG<=0?null:IndicadoresFaenaTabla.OBJ_DG<IndicadoresFaenaTabla.LUN_DG?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.LUN_DG>0?IndicadoresFaenaTabla.LUN_DG+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MAR_DG<=0?null:IndicadoresFaenaTabla.OBJ_DG<IndicadoresFaenaTabla.MAR_DG?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MAR_DG>0?IndicadoresFaenaTabla.MAR_DG+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MIE_DG<=0?null:IndicadoresFaenaTabla.OBJ_DG<IndicadoresFaenaTabla.MIE_DG?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MIE_DG>0?IndicadoresFaenaTabla.MIE_DG+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.JUE_DG<=0?null:IndicadoresFaenaTabla.OBJ_DG<IndicadoresFaenaTabla.JUE_DG?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.JUE_DG>0?IndicadoresFaenaTabla.JUE_DG+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.VIE_DG<=0?null:IndicadoresFaenaTabla.OBJ_DG<IndicadoresFaenaTabla.VIE_DG?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.VIE_DG>0?IndicadoresFaenaTabla.VIE_DG+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.SAB_DG<=0?null:IndicadoresFaenaTabla.OBJ_DG<IndicadoresFaenaTabla.SAB_DG?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.SAB_DG>0?IndicadoresFaenaTabla.SAB_DG+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.DOM_DG<=0?null:IndicadoresFaenaTabla.OBJ_DG<IndicadoresFaenaTabla.DOM_DG?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.DOM_DG>0?IndicadoresFaenaTabla.DOM_DG+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaCAlculo.RSEM_DG<=0?null:IndicadoresFaenaTabla.OBJ_DG<IndicadoresFaenaCAlculo.RSEM_DG?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaCAlculo.RSEM_DG}%
                        </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>% DECOMISO<br/>PLANTA</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><SouthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_DP>0?IndicadoresFaenaTabla.OBJ_DP+"%":null}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_DP>0?IndicadoresFaenaTabla.RSA_DP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.LUN_DP<=0?null:IndicadoresFaenaTabla.OBJ_DP<IndicadoresFaenaTabla.LUN_DP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.LUN_DP>0?IndicadoresFaenaTabla.LUN_DP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MAR_DP<=0?null:IndicadoresFaenaTabla.OBJ_DP<IndicadoresFaenaTabla.MAR_DP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MAR_DP>0?IndicadoresFaenaTabla.MAR_DP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MIE_DP<=0?null:IndicadoresFaenaTabla.OBJ_DP<IndicadoresFaenaTabla.MIE_DP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MIE_DP>0?IndicadoresFaenaTabla.MIE_DP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.JUE_DP<=0?null:IndicadoresFaenaTabla.OBJ_DP<IndicadoresFaenaTabla.JUE_DP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.JUE_DP>0?IndicadoresFaenaTabla.JUE_DP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.VIE_DP<=0?null:IndicadoresFaenaTabla.OBJ_DP<IndicadoresFaenaTabla.VIE_DP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.VIE_DP>0?IndicadoresFaenaTabla.VIE_DP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.SAB_DP<=0?null:IndicadoresFaenaTabla.OBJ_DP<IndicadoresFaenaTabla.SAB_DP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.SAB_DP>0?IndicadoresFaenaTabla.SAB_DP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.DOM_DP<=0?null:IndicadoresFaenaTabla.OBJ_DP<IndicadoresFaenaTabla.DOM_DP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.DOM_DP>0?IndicadoresFaenaTabla.DOM_DP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaCAlculo.RSEM_DP<=0?null:IndicadoresFaenaTabla.OBJ_DP<IndicadoresFaenaCAlculo.RSEM_DP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaCAlculo.RSEM_DP}%
                        </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>% EXPURGO</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><SouthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_EXP>0?IndicadoresFaenaTabla.OBJ_EXP+"%":null}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_EXP>0?IndicadoresFaenaTabla.RSA_EXP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.LUN_EXP<=0?null:IndicadoresFaenaTabla.OBJ_EXP<IndicadoresFaenaTabla.LUN_EXP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.LUN_EXP>0?IndicadoresFaenaTabla.LUN_EXP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MAR_EXP<=0?null:IndicadoresFaenaTabla.OBJ_EXP<IndicadoresFaenaTabla.MAR_EXP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MAR_EXP>0?IndicadoresFaenaTabla.MAR_EXP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MIE_EXP<=0?null:IndicadoresFaenaTabla.OBJ_EXP<IndicadoresFaenaTabla.MIE_EXP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MIE_EXP>0?IndicadoresFaenaTabla.MIE_EXP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.JUE_EXP<=0?null:IndicadoresFaenaTabla.OBJ_EXP<IndicadoresFaenaTabla.JUE_EXP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.JUE_EXP>0?IndicadoresFaenaTabla.JUE_EXP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.VIE_EXP<=0?null:IndicadoresFaenaTabla.OBJ_EXP<IndicadoresFaenaTabla.VIE_EXP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.VIE_EXP>0?IndicadoresFaenaTabla.VIE_EXP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.SAB_EXP<=0?null:IndicadoresFaenaTabla.OBJ_EXP<IndicadoresFaenaTabla.SAB_EXP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.SAB_EXP>0?IndicadoresFaenaTabla.SAB_EXP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.DOM_EXP<=0?null:IndicadoresFaenaTabla.OBJ_EXP<IndicadoresFaenaTabla.DOM_EXP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.DOM_EXP>0?IndicadoresFaenaTabla.DOM_EXP+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaCAlculo.RSEM_EXP<=0?null:IndicadoresFaenaTabla.OBJ_EXP<IndicadoresFaenaCAlculo.RSEM_EXP?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaCAlculo.RSEM_EXP}%
                        </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>% GARRA A PESO<br/>VIVO</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><NorthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_GPV>0?IndicadoresFaenaTabla.OBJ_GPV+"%":null}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_GPV>0?IndicadoresFaenaTabla.RSA_GPV+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.LUN_GPV<=0?null:IndicadoresFaenaTabla.OBJ_GPV>IndicadoresFaenaTabla.LUN_GPV?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.LUN_GPV>0?IndicadoresFaenaTabla.LUN_GPV+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MAR_GPV<=0?null:IndicadoresFaenaTabla.OBJ_GPV>IndicadoresFaenaTabla.MAR_GPV?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MAR_GPV>0?IndicadoresFaenaTabla.MAR_GPV+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.MIE_GPV<=0?null:IndicadoresFaenaTabla.OBJ_GPV>IndicadoresFaenaTabla.MIE_GPV?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.MIE_GPV>0?IndicadoresFaenaTabla.MIE_GPV+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.JUE_GPV<=0?null:IndicadoresFaenaTabla.OBJ_GPV>IndicadoresFaenaTabla.JUE_GPV?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.JUE_GPV>0?IndicadoresFaenaTabla.JUE_GPV+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.VIE_GPV<=0?null:IndicadoresFaenaTabla.OBJ_GPV>IndicadoresFaenaTabla.VIE_GPV?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.VIE_GPV>0?IndicadoresFaenaTabla.VIE_GPV+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.SAB_GPV<=0?null:IndicadoresFaenaTabla.OBJ_GPV>IndicadoresFaenaTabla.SAB_GPV?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.SAB_GPV>0?IndicadoresFaenaTabla.SAB_GPV+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaTabla.DOM_GPV<=0?null:IndicadoresFaenaTabla.OBJ_GPV>IndicadoresFaenaTabla.DOM_GPV?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaTabla.DOM_GPV>0?IndicadoresFaenaTabla.DOM_GPV+"%":null}
                        </th>
                        <th scope="row" className={IndicadoresFaenaCAlculo.RSEM_GPV<=0?null:IndicadoresFaenaTabla.OBJ_GPV>IndicadoresFaenaCAlculo.RSEM_GPV?'table-data-positive':'table-data-negative'} style={{fontSize: 26}}>
                          {IndicadoresFaenaCAlculo.RSEM_GPV}%
                        </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>ACCIDENTES</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><SouthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_AC}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_AC}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.LUN_AC}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.MAR_AC}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.MIE_AC}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.JUE_AC}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.VIE_AC}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.SAB_AC}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.DOM_AC}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_AC}</th>
                      </tr>
                      <tr>
                        <th scope="row"  style={{verticalAlign: 'middle', fontSize: 20, fontWeight: 'bolder'}}>CONSUMO DE<br/>AGUA (M3)</th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          <span className="float-left"><SouthIcon color="action"/></span>
                          {IndicadoresFaenaTabla.OBJ_CA+"M3"}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.RSA_CA}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.LUN_CA}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.MAR_CA}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.MIE_CA}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.JUE_CA}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.VIE_CA}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.SAB_CA}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>
                          {IndicadoresFaenaTabla.DOM_CA}
                        </th>
                        <th scope="row" style={{verticalAlign: 'middle', fontSize: 26, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_CA}M3</th>
                      </tr>

                    </tbody>
                  </table>

                </div>
              </div>
              <div className="col-12 text-center">version {VERSION}</div>
            </div>
          </div>
        </div>
      </div>

      {ShowmetheModal_ ? (
        <div className="modal_chdata">
          <button
            onClick={() => setShowmetheModal_(false)}
            className="btn btn-danger btn-sm"
            style={{ position: "absolute", top: "10px", left: "50%" }}
          >
            Cerrar
          </button>
          <div className="bg-info w-100 text-center">
            <LineFour DATA={MedidorToModal} />
            {/* <LineFive DATA={MedidorToModal} /> */}
          </div>
        </div>
      ) : null}

      {ShowmetheModal_ACUM ? (
        <div className="modal_chdata">
          <button
            onClick={() => setShowmetheModal_ACUM(false)}
            className="btn btn-danger btn-sm"
            style={{ position: "absolute", top: "10px", left: "50%" }}
          >
            Cerrar
          </button>
          <div className="bg-info w-100 text-center">
            {/* <LineFour DATA={MedidorToModal} /> */}
            <LineFive DATA={MedidorToModal} />
          </div>
        </div>
      ) : null}
    </>
  );
};
